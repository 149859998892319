import { Form, Select } from "antd";
import { settingInterface } from "DTO/interfaces";
import { useInfiniteSamples } from "hooks/query/samples/useSamples";
import { SampleModel } from "models/samples";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadingMoreSpinner } from "utils/ui/ScrollLoading";

type Props = { save: () => void; disabled: boolean };
const { Option } = Select;

function SelectSamples({ save, disabled }: Props) {
  const [sampleSettings, setSampleSettings] = useState<
    settingInterface<SampleModel[]>
  >({
    page: 1,
    perPage: 6,
    loadingMore: false,
    data: [],
    search: "",
  });
  const samples = useInfiniteSamples(
    sampleSettings.page,
    sampleSettings.perPage ?? 12,
    sampleSettings.search
  );

  const changeClientSearch = (e: any) => {
    let newArr = sampleSettings;
    newArr.search = e;
    newArr.loadingMore = false;
    newArr.page = 1;
    setSampleSettings({ ...newArr });
  };
  const handleClientScroll = (event: any) => {
    let newArr = sampleSettings;
    if (sampleSettings.page === samples?.data?.pages[0].meta.lastPage) {
      newArr.loadingMore = false;
      return;
    }
    let delta: any;

    let newMin = newArr.page;
    if (event.wheelDelta) {
      delta = event.wheelDelta;
    } else {
      delta = -1 * event.deltaY;
    }
    if (!newArr.loadingMore) {
      if (delta < 0) {
        newMin = newArr.page + 1;
        let length = newArr.data.length;
        if (length !== undefined) {
          newArr.loadingMore = true;
          newArr.page = newArr.page + 1;
          samples.fetchNextPage();
        }
      } else if (delta > 0 && newArr.page >= 1) {
        newMin = newArr.page - 1;
      }
    }
    newArr.page = newMin;
    setSampleSettings({ ...newArr });
  };

  const filterOption = () => {
    return sampleSettings.data;
  };

  useEffect(() => {
    if (samples.status === "success") {
      let allSamples: any = [];

      samples?.data?.pages &&
        samples?.data?.pages[0] !== undefined &&
        samples?.data?.pages.forEach((page) => {
          if (page !== null) allSamples = [...allSamples, ...page.data];
        });
      let newArr = sampleSettings;
      newArr.data = allSamples;

      if (
        samples?.data?.pages[samples?.data?.pages.length - 1]?.data.length > 0
      ) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setSampleSettings({ ...newArr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples?.data?.pages]);

  const { t } = useTranslation();

  return (
    <Form.Item name="sample" style={{ width: "100%", margin: 0 }}>
      <Select
        onBlur={save}
        showSearch
        filterOption={filterOption as any}
        onSearch={(e: any) => changeClientSearch(e)}
        dropdownRender={(menu: any) => (
          <div onWheel={handleClientScroll}>{menu}</div>
        )}
        showArrow
        disabled={disabled}
      >
        {samples.isLoading || samples.isFetching
          ? loadingMoreSpinner
          : sampleSettings.data.map((item) => (
              <Option
                style={{ direction: "rtl", textAlign: "right" }}
                key={item?.id?.toString()}
                value={item?.id?.toString()}
              >
                {item?.name}
              </Option>
            ))}
      </Select>
    </Form.Item>
  );
}

export default SelectSamples;
