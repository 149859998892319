import React, { ReactNode, useEffect, useState } from "react";

import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import CPLevels from "pages/controlPanel/levels";
import CPServices from "pages/controlPanel/services";
import CPCategories from "pages/controlPanel/categories";
import { Roles } from "utils/helpers/static_data";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import ProtectedRoute from "./ProtectedRoute.routes";
import useCurrentUser from "hooks/query/users/useCurrentUser";
import { isClient, isTechnical } from "utils/helpers/check-roles";
import ClientCategories from "pages/controlPanel/client-categories";
import FinalReports from "pages/final-reports";
import Places from "pages/controlPanel/places";
import PlanDetails from "pages/myPlan/plan/PlanDetails";

const Home = React.lazy(() => import("pages/home"));

const ClientRequests = React.lazy(() => import("pages/clientRequests"));

const Tickets = React.lazy(() => import("pages/tickets"));
const Ticket = React.lazy(() => import("pages/tickets/ticket/TicketDetails"));

const Technicians = React.lazy(() => import("pages/technicians"));
const Technical = React.lazy(() => import("pages/technicians/technical"));

const Profile = React.lazy(() => import("pages/profile"));

const Companies = React.lazy(() => import("pages/companies"));

const Users = React.lazy(() => import("pages/users"));
const User = React.lazy(() => import("pages/users/user"));
const Doctor = React.lazy(() => import("pages/users/doctor"));

const Clients = React.lazy(() => import("pages/clients"));
const Client = React.lazy(() => import("pages/clients/client"));

const ControlPanel = React.lazy(() => import("pages/controlPanel"));
const CommissionReports = React.lazy(
  () => import("pages/reports/techniciansCommissions")
);

const SystemSettings = React.lazy(() => import("pages/systemSettings"));

const ClientTickets = React.lazy(() => import("pages/client-tickets"));

const Customers = React.lazy(() => import("pages/customers"));

const Dashboard = React.lazy(() => import("pages/dashboard"));

const MyPlan = React.lazy(() => import("pages/myPlan"));

const PlansPage = React.lazy(() => import("pages/plans"));

const Plan = React.lazy(() => import("pages/myPlan/plan/PlanDetails"));

const DorctorCategories = React.lazy(
  () => import("pages/controlPanel/DoctorCategories")
);

const DoctorSpecs = React.lazy(() => import("pages/controlPanel/DoctorSpecs"));
const Pharmacies = React.lazy(() => import("pages/controlPanel/pharmacies"));
const Samples = React.lazy(() => import("pages/controlPanel/samples"));

interface RouteInterface {
  path: string;
  component: ReactNode;
  name: string;
  permissions?: string[];
  children?: ReactNode[];
  roles?: any;
  allowedIds?: number[];
}

const routes: Array<RouteInterface> = [
  {
    path: "/",
    component: <Home />,
    name: "Home",
    permissions: ["browse_tickets"],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
    permissions: ["browse_dashboard"],
    roles: [Roles.dispatcher],
    allowedIds: [291, 513],
  },
  {
    path: "/final-reports",
    name: "FinalReports",
    component: <FinalReports />,
    permissions: ["browse_final_reports"],
  },
  {
    path: "/client-requests",
    component: <ClientRequests />,
    name: "ClientRequests",
    permissions: ["browse_tickets"],
    roles: [
      Roles.accountant,
      Roles.admin,
      Roles.dispatcher,
      Roles.distributor,
      Roles.operation,
      Roles.sales,
      Roles.technical,
      Roles.coordinator,
    ],
  },
  {
    path: "/plans",
    component: <PlansPage />,
    name: "plans",
    permissions: ["browse_plans"],
    roles: [
      Roles.accountant,
      Roles.admin,
      Roles.dispatcher,
      Roles.distributor,
      Roles.operation,
      Roles.sales,
      Roles.technical,
      Roles.coordinator,
    ],
  },
  {
    path: "/client-requests/:client_request_id",
    component: <Tickets />,
    name: "Tickets",
    permissions: ["browse_tickets"],
    roles: [
      Roles.accountant,
      Roles.admin,
      Roles.dispatcher,
      Roles.distributor,
      Roles.operation,
      Roles.sales,
      Roles.technical,
      Roles.coordinator,
    ],
  },

  {
    path: "/tickets/:id",
    component: <Ticket />,
    name: "Ticket",
    permissions: ["read_tickets"],
    roles: [
      Roles.accountant,
      Roles.admin,
      Roles.dispatcher,
      Roles.distributor,
      Roles.operation,
      Roles.sales,
      Roles.technical,
      Roles.coordinator,
    ],
  },

  {
    path: "/technicians",
    component: <Technicians />,
    name: "Technicians",
    permissions: ["browse_technical_profiles"],
  },
  {
    path: "/technicians/:id",
    component: <Technical />,
    name: "Technical",
    permissions: ["read_technical_profiles"],
  },
  {
    path: "/profile",
    component: <Profile />,
    name: "Profile",
  },
  {
    path: "/users",
    component: <Users />,
    name: "Users",
    permissions: ["browse_users"],
  },
  {
    path: "/users/:id",
    component: <User />,
    name: "User",
    permissions: ["read_users"],
  },
  {
    path: "/doctors/:id",
    component: <Doctor />,
    name: "Doctor",
    permissions: ["read_users"],
  },
  {
    path: "/reports",
    component: <CommissionReports />,
    name: "Reports",
    permissions: ["read_ticket_percentage"],
  },
  {
    path: "/system-settings",
    component: <SystemSettings />,
    name: "System Settings",
    permissions: ["browse_settings", "read_settings"],
  },
  {
    path: "/control-panel",
    component: <ControlPanel />,
    name: "control panel",
    permissions: ["browse_categories", "read_categories"],
    children: [
      { path: "levels", component: <CPLevels />, name: "cp-levles" },
      { path: "services", component: <CPServices />, name: "cp-services" },
      // {
      //   path: "categories",
      //   component: <CPCategories />,
      //   name: "cp-categories",
      // },
      {
        path: "clients-categories",
        component: <ClientCategories />,
        name: "cp-clients-categories",
      },
      { path: "pharmacies", component: <Pharmacies />, name: "pharmacies" },
      {
        path: "doctor-categories",
        component: <DorctorCategories />,
        name: "places",
      },
      {
        path: "doctor-specs",
        component: <DoctorSpecs />,
        name: "doctor-specs",
      },
      { path: "places", component: <Places />, name: "places" },
      { path: "samples", component: <Samples />, name: "samples" },
    ],
    roles: [Roles.dispatcher, Roles.operation, Roles.sales, Roles.coordinator],
  },
  {
    path: "/c-tickets",
    component: <ClientTickets />,
    name: "client tickets",
    permissions: ["browse_tickets"],
    roles: [Roles.client],
  },
  {
    path: "/c-tickets/:id",
    component: <Ticket />,
    name: "client ticket details",
    permissions: ["browse_tickets"],
    roles: [Roles.client],
  },
  {
    path: "/customers",
    component: <Customers />,
    name: "Customers",
    permissions: ["browse_companies"],
  },
  {
    path: "/customers/clients/:id",
    component: <Client />,
    name: "Client",
    permissions: ["browse_companies", "browse_users"],
  },
  {
    path: "/my-plan",
    component: <MyPlan />,
    name: "myPlan",
    roles: [Roles.technical],
  },
  {
    path: "/my-plan/:id",
    component: <Plan />,
    name: "plan",
    roles: [Roles.technical],
  },
  {
    path: "*",
    component: (
      <Navigate
        to={isTechnical(UserData.Decrypt()?.role_id!) ? "my-plan" : "/plans"}
      />
    ),
    name: "default",
    permissions: ["browse_tickets"],
  },
];

let isInit = true;

const PagesRoutes = () => {
  const location = useLocation();
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);
  const { refetch } = useCurrentUser({ enabled: false });

  useEffect(() => {
    if (isInit) {
      refetch().then((response) => {
        if (response.data?.data) {
          UserData.Encrypt(response.data.data);
        }
      });
    }
  }, []);
  return (
    <Routes location={location}>
      {routes.map((route) => {
        if (route.children) {
          return (
            <Route
              path={route.path}
              element={
                <ProtectedRoute
                  roles={route.roles}
                  permissions={route.permissions}
                >
                  {route.component}
                </ProtectedRoute>
              }
              key={route.name}
            >
              {route.children.map((route: any) => (
                <Route
                  path={route.path}
                  element={route.component}
                  key={route.name}
                />
              ))}
            </Route>
          );
        }
        return (
          <Route
            key={route.name}
            path={route.path}
            element={
              <ProtectedRoute
                roles={route.roles}
                permissions={route.permissions}
                allowedIds={route.allowedIds}
              >
                {route.component}
              </ProtectedRoute>
            }
          />
        );
      })}
      <Route
        path="/control-panel/*"
        element={<Navigate to="/control-panel/services" />}
      />
    </Routes>
  );
};

export default PagesRoutes;
