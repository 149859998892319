import { LastDoctorSampleModel } from "models/samples";
import { useQuery } from "react-query";
import { FetchLastDoctorSamples } from "services/samples";
import ApiResult from "utils/api/models/api-result";

export default function useLastDoctorSamples(id: number) {
  return useQuery<ApiResult<LastDoctorSampleModel[]>>(
    ["lastDoctorSamples", id],
    () => FetchLastDoctorSamples(id)
  );
}
