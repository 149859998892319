import { AxiosRequestConfig } from "axios";
import { PlanModel } from "models/plan";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class SamplesServices extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }
  public async Fetch(
    page: number,
    perPage: number,
    search?: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/samples?page=${page}&items_per_page=${perPage}${
        search ? `&name=${search}` : ""
      }`
    );
  }
  public async Update(id: number, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/samples/${id}`, data);
  }
  public async Post(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/samples`, data);
  }

  public async Delete(id: number): Promise<ApiResponse<any>> {
    return this.delete(`/api/samples/${id}`);
  }

  public async FetchLastDoctorSamples(id: number): Promise<ApiResponse<any>> {
    return this.get(`/api/lastDoctorsamples/${id}`);
  }
}

const samplesServices = new SamplesServices();

export const FetchSamples = async (
  page: number,
  perPage: number,
  search?: string
) => {
  const result: any = await samplesServices.Fetch(page, perPage, search);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UpdateSample = async (id: number, data: any) => {
  const result: any = await samplesServices.Update(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const AddSample = async (data: any) => {
  const result: any = await samplesServices.Post(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const DeleteSample = async (id: number) => {
  const result: any = await samplesServices.Delete(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const FetchLastDoctorSamples = async (id: number) => {
  const result: any = await samplesServices.FetchLastDoctorSamples(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
