import React, { useContext, useEffect, useRef, useState } from "react";
import type { InputRef, TableProps } from "antd";
import { Button, Form, Input, Popconfirm, Table } from "antd";
import SelectSamples from "components/selects/SelectSamples";
import { useTranslation } from "react-i18next";
import { BiPlus } from "react-icons/bi";
import { PlusOutlined } from "@ant-design/icons";
import AddSampleModal from "components/Modals/addSampleModal";
import useAddSample from "hooks/query/samples/useAddSample";
import { SampleType } from "components/Modals/reportModal";

type FormInstance<T> = any;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  sample: string;
  request_percent: number;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  type: "input" | "select";
  disabled: boolean;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  disabled,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const form = useContext(EditableContext)!;

  const { t } = useTranslation();

  useEffect(() => {
    if (record) form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  }, [record]);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    // form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    type === "select"
      ? (childNode = <SelectSamples save={save} disabled={disabled} />)
      : (childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                validator(_, value) {
                  if (value && (parseInt(value) < 0 || parseInt(value) > 100)) {
                    return Promise.reject(
                      new Error(t("must_be_between_0_and_100"))
                    );
                  }
                  return Promise.resolve();
                },
              },
              {
                required: form.getFieldValue("sample"),
                message: `${t("please_input")} ${title}`,
              },
            ]}
          >
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              disabled={disabled}
              type="number"
            />
          </Form.Item>
        ));
  }

  return <td {...restProps}>{childNode}</td>;
};

interface DataType {
  key: React.Key;
  sample: string;
  request_percent: number;
  sample_quantity: number;
}

type ColumnTypes = Exclude<TableProps<DataType>["columns"], undefined>;

const AddMultiSamplesTable = ({
  dataSource,
  setDataSource,
  disabled,
  form,
}: {
  dataSource: SampleType[];
  setDataSource: React.Dispatch<React.SetStateAction<SampleType[]>>;
  disabled: boolean;
  form: FormInstance<any>;
}) => {
  const { t } = useTranslation();

  const [count, setCount] = useState(2);

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const add = useAddSample();
  const [addOpen, setAddOpen] = useState(false);
  const addPlacesHandler = ({ data }: any) => {
    add.mutateAsync(data).then(() => {
      setAddOpen(false);
    });
  };

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: (
        <>
          {t("sample")}
          <Button
            size="small"
            style={{ margin: "5px" }}
            onClick={() => setAddOpen(true)}
            icon={<PlusOutlined style={{ fontSize: "10px" }} />}
            disabled={disabled}
          ></Button>
        </>
      ),
      dataIndex: "sample",
      width: "30%",
      editable: true,
    },
    {
      title: t("percent"),
      dataIndex: "request_percent",
      editable: true,
      width: "20%",
    },
    {
      title: t("sample_quantity"),
      dataIndex: "sample_quantity",
      editable: true,
      width: "20%",
    },
    {
      title: "",
      dataIndex: "operation",
      width: "10%",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Button
            type="link"
            title="Sure to delete?"
            onClick={() => handleDelete(record.key)}
            disabled={disabled}
          >
            {t("delete")}
          </Button>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      sample: "",
      request_percent: 0,
      sample_quantity: 0,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.dataIndex === "request_percent" || col.dataIndex === "sample_quantity" ? "input" : "select",
        disabled: disabled,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table<DataType>
        title={() => "العينات"}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        pagination={false}
      />
      <Button
        onClick={handleAdd}
        type="primary"
        style={{ marginTop: 16 }}
        disabled={disabled}
      >
        {t("add_sample")}
      </Button>
      <AddSampleModal
        open={addOpen}
        setOpen={setAddOpen}
        isLoading={add.isLoading}
        onAdd={addPlacesHandler}
      />
    </div>
  );
};

export default AddMultiSamplesTable;
