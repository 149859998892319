import { Button, Modal, Pagination, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import useLastDoctorSamples from "hooks/query/samples/useLastDoctorSamples";
import { LastDoctorSampleModel } from "models/samples";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import format from "utils/helpers/date-format";

const LastDoctorSamples = ({
  id,
  btnClassName,
}: {
  id: number;
  btnClassName?: string;
}) => {
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useLastDoctorSamples(id);

  const { t } = useTranslation();

  const columns: ColumnsType<LastDoctorSampleModel> = [
    {
      title: t("sample"),
      dataIndex: "samples",
      align: "center",
      render: (sample: LastDoctorSampleModel["samples"]) => <>{sample.name}</>,
    },
    {
      title: t("percent"),
      dataIndex: "request_percent",
      align: "center",
    },
    {
      title: t("created_at"),
      dataIndex: "created_at",
      align: "center",
      render: (value) => (
        <span dir="ltr">{dayjs(value).format(format.date_time)}</span>
      ),
    },
  ];

  return (
    <>
      <Button
        className={btnClassName}
        type="primary"
        onClick={() => setOpen(true)}
      >
        {t("last_samples")}
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={t("last_samples")}
        centered
        width={800}
        footer={[
          <Button type="default" onClick={() => setOpen(false)}>
            {t("close")}
          </Button>,
        ]}
      >
        <Table
          loading={isLoading}
          dataSource={data?.data}
          columns={columns}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default LastDoctorSamples;
