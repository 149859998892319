/* eslint-disable react-hooks/exhaustive-deps */
import { FileTextFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Roles, Ticket } from "utils/helpers/static_data";
import FormalReportModal from "components/Modals/formalReportModal";
import ReportsModal from "components/Modals/reportsModal";
import PermissionsGate from "components/permissionsGate";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import { TicketModel } from "models/tickets";
import { filters } from "components/filters/clientRequestsFillters";
import { t } from "i18next";
import LastDoctorSamples from "components/Modals/lastDoctorSamples/LastDoctorSamples";
import { isTechnical } from "utils/helpers/check-roles";

interface ReportsProps {
  ticket: TicketModel;
  Status: filters["Status"];
  refetch: any;
  notEvent?: string;
}

const Reports = ({ ticket, Status, refetch, notEvent }: ReportsProps) => {
  let { id }: any = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [IsModalFormal, setIsModalFormal] = useState(false);
  const queryClient = useQueryClient();

  const location = useLocation();
  const navigate = useNavigate();

  const [notificationsCount, setNotificationsCount] = useState<number>();

  const openReports = () => {
    setIsModalVisible(true);
    queryClient.invalidateQueries("NotificationsNumber");
  };

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);

  useEffect(() => {
    if (ticket && ticket.notifications_count) {
      setNotificationsCount(ticket?.notifications_count?.reports_count);
    }
  }, [ticket]);

  useEffect(() => {
    if (notEvent && notEvent === "TicketReport") {
      setIsModalVisible(true);
    }
  }, [notEvent]);

  useEffect(() => {
    if (isModalVisible) {
      setNotificationsCount(0);
    }
  }, [isModalVisible]);

  const client = User?.role_id === Roles.client;

  return (
    <>
      {client &&
        Status !== Ticket.create &&
        Status !== Ticket.pending &&
        Status !== Ticket.priced &&
        Status !== Ticket.active && (
          <Button
            onClick={() => setIsModalFormal(true)}
            className="addRwview"
            key="add"
            type="primary"
          >
            {t("final_report")}
          </Button>
        )}
      {isTechnical(User?.role_id!) && (
        <LastDoctorSamples btnClassName="addRwview" id={ticket.user.id} />
      )}
      {/* {IsModalFormal && (
        <FormalReportModal
          modal={IsModalFormal}
          id={id}
          Status={Status}
          close={() => setIsModalFormal(false)}
        />
      )} */}
      <>
        {!client && (
          <div
            onClick={() => openReports()}
            className={
              Status === Ticket.create ||
              Status === Ticket.pending ||
              Status === Ticket.priced
                ? "addRwview disabled"
                : "addRwview"
            }
          >
            {t("technical_reports")} <FileTextFilled />
            {notificationsCount !== undefined && notificationsCount !== 0 && (
              <div className="notificationNum">{notificationsCount}</div>
            )}
          </div>
        )}

        <ReportsModal
          isLeader={ticket?.is_leader}
          Status={Status}
          id={ticket.id}
          schedule={ticket?.start_schedule}
          modal={isModalVisible}
          close={() => {
            setIsModalVisible(false);
            if (notEvent && notEvent === "TicketReport") {
              const { pathname } = location;
              navigate(pathname, { replace: true });
            }
          }}
          checkType={ticket?.check_type}
          active={ticket?.currently_active}
          trCheckType={ticket?.transportation_check_type}
          transportationStatus={ticket?.transportation_status}
        />
      </>
    </>
  );
};

export default Reports;
