import { ApiError } from "utils/api/models/api-error";
import { useInfiniteQuery, useQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { FetchSamples } from "services/samples";
import { SampleModel } from "models/samples";

export default function useSamples(page: number, perPage: number) {
  const queryKey = ["samples", page, perPage];
  return {
    ...useQuery<ApiResult<SampleModel[]>, ApiError>(queryKey, () =>
      FetchSamples(page, perPage)
    ),
  };
}

export function useInfiniteSamples(
  page?: number,
  perPage?: any,
  search?: string
) {
  const queryKey = ["samples", perPage, search];
  return {
    ...useInfiniteQuery<ApiResult<SampleModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) => FetchSamples(pageParam, perPage, search),
      {
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}
